export const sk = {
  'aboutUs.dev1Text':
    'Zakladateľ ﬁrmy, pôsobil viac ako 12 rokov v Prahe, kde aj získal doktorát z informatiky\n' +
    'na Matematicko-fyzikálnej fakulte Univerzity Karlovej. Aj vďaka tomu sú aktuálni klienti prevažne z Prahy. Ako\n' +
    'softvérový inžinier pracuje už 10 rokov a v oblasti frontendového vývoja 5 rokov, preto nastal čas\n' +
    'zúročiť svoje skúsenosti a predať ich ďalej v rámci ﬁrmy svojim zamestnancom.',
  'aboutUs.title': 'Kto sme',
  'aboutUs.titleText': 'Dovoľte, aby sme sa Vám predstavili.',
  'aboutUs.char.charText':
    'Sme ﬁrma poskytujúca služby vývoja frontendu pre webové aplikácie. Pre vývoj a\n' +
    'udržiavanie aplikácií je pre nás dôležité ich testovanie, dokumentácia a použitie kvalitných\n' +
    'vývojárskych nástrojov v zmysle myšlienky "Najprv si vyber dobrú lopatu, priprav si pracovné\n' +
    'prostredie a až potom začni kopať". Okrem profesionálnej práce nám veľmi záleží na ľudských a kresťanských\n' +
    'hodnotách v rámci pracovného tímu, ale aj voči našim klientom a obchodným partnerom. Zakladáme si na\n' +
    ' úprimnej komunikácii a zodpovednosti k práci, vážení si jeden druhého a chcení pre druhých to\n' +
    'najlepšie. Preto chceme tvoriť kvalitný softvér, ktorý našim klientom uľahčí život.',
  'aboutUs.char.title': 'Charakteristika spoločnosti',
  'aboutUs.name.nameAndText':
    'Sincersoft - vyslovuj [Sin’si(ǝ)rsoft] (alebo jednoducho [Sinsírsoft])',
  'aboutUs.name.nameText':
    'Pri hľadaní názvu našej spoločnosti sme zamýšľali nad tým, aby názov, okrem samotného profesného\n' +
    'zamerania, charakterizoval aj naše hodnoty, ktoré považujeme za dôležité. Za jednu z najdôležitejšich\n' +
    'hodnôt spolupráce, či už s obchodnými partnermi, alebo vzájomne medzi sebou, považujeme úprimnosť. Preto\n' +
    'náš názov vychádza z latinského prekladu slova úprimnosť – sinceritate [sinčeritáte], v tomto latinskom\n' +
    'slove má základ aj anglický preklad sincerity [sin’seriti]. Keďže však už pri zakladaní sme mali zámer,\n' +
    'aby naša ﬁrma ponúkala služby aj zahraničným klientom, tak sme sa rozhodli výslovnosť názvu založiť na anglickej výslovnosti slova úprimný - sincere [sin’si(ǝ)r].',
  'aboutUs.name.nameTitle': 'Pôvod názvu spoločnosti',
  'aboutUs.team.position.developer': 'Softverový vývojár',
  'aboutUs.team.position.owner': 'Zakladateľ, CEO, CTO, Vedúci vývoja',
  'aboutUs.team.title': 'Náš tím',
  'aboutUs.heading.weAre': 'Sme',
  'aboutUs.heading.sincersoft': 'Sincersoft',
  'aboutUs.heading.message':
    'Softvérová spoločnosť poskytujúca služby vývoja pre webové a mobilné aplikácie.',
  'blog.ad.text': 'Navrhneme vám najefektívnejšie riešenie Vášho projektu.',
  'blog.ad.title':
    'Máte výborný nápad na aplikáciu? Neváhajte nás kontaktovať.',
  'blog.category': 'Kategórie',
  'callToAction.writeUs': 'POVEDZTE NÁM O SVOJOM PROJEKTE',
  'contact.title': 'Kontakt',
  'contact.button': 'Kontaktujte nás',
  'contact.writeUs': 'Napíšte nám',
  'contact.titleText': 'Na Vaše otázky radi odpovieme.',
  'contact.form.law':
    'Spoločnosť Sincersoft s.r.o. je zapísaná v Obchodnom registri Okresného súdu Prešov, vložka 37460/P',
  'contact.form.message': 'Správa',
  'contact.form.name': 'Meno (názov firmy)',
  'contact.form.select': 'Plánovaný rozpočet',
  'contact.form.budgetLessThan': 'menej ako 5 000 €',
  'contact.form.budgetFiveToTen': '5 000 € - 10 000 €',
  'contact.form.budgetTenToThirty': '10 000 € - 30 000 €',
  'contact.form.budgetThirtyToHundred': '30 000 € - 100 000 €',
  'contact.form.budgetMoreThan': 'nad 100 000 €',
  'contact.form.budgetDefault': 'Vybrať rozpočet',
  'contact.form.submit': 'Odoslať',
  'contact.form.success': 'E-mail bol úspešne odoslaný.',
  'contact.form.error':
    'Nastala chyba pri odosielaní e-mailu. Ak sa bude chyba opakovať kontaktuje nás na info@sincersoft.com',
  'contact.form.title': 'Povedzte nám o svojom projekte',
  'contact.form.info':
    'Napíšte nám a my Vás do nasledujúceho pracovného dňa kontaktujeme a navrhneme ďalšie kroky.',
  'contact.form.phoneNumber': 'Mobilné číslo',
  'contact.form.email.subject': 'Kontaktný formulár spoločnosti Sincersoft',
  'contact.info.billingAddress': 'fakturačná adresa',
  'contact.info.billingInfo': 'Fakturačné údaje',
  'contact.info.companyCountry': 'Slovenská republika',
  'contact.info.companyPersonPosition': 'CEO, Obchod',
  'contact.info.emailSecurityInfo':
    'Z bezpečnostných dôvodov je email uvedený iba vo formáte na čítanie',
  'contact.info.headquarters': 'Sídlo',
  'contact.info.mandatoryEmailAndMessageMessage':
    'Email a správa musia byť vyplnené.',
  'contact.info.mandatoryEmailMessage': 'Email musí byť vyplnený.',
  'contact.info.mandatoryMessageMessage': 'Správa musí byť vyplnená.',
  'contact.info.mandatoryBudgetMessage': 'Plánovaný rozpočet musí byť zvolený.',
  'contact.info.office': 'Kancelária',
  'contact.info.officeStreet': 'Baštová 1467/18, 1. poschodie',
  'contact.info.person': 'Kontaktná osoba',
  'contact.info.phone': 'Telefón',
  'contact.info.position': 'Pozícia',
  'contact.info.regNo': 'IČO',
  'contact.info.vatId': 'IČ DPH',
  'contact.map.warningMessage': 'Google mapy sú aktuálne nedostupné.',
  'cookiesInfo.content.section1': 'Čo sú cookies?',
  'cookiesInfo.content.section1.list1.item1':
    'pre povolenie nejakej funkcionality',
  'cookiesInfo.content.section1.list1.item2':
    'pre použitie analytických nástrojov',
  'cookiesInfo.content.section1.list1.item3': 'pre uloženie Vašich nastavení',
  'cookiesInfo.content.section1.list1.item4':
    'pre povolenie a umožnenie cielenej reklamy',
  'cookiesInfo.content.section1.paragraph1':
    'Cookies sú malé textové súbory uložené vo Vašom internetovom prehliadači, ktoré umožňujú, aby {company} alebo spoločnosti tretích strán Vás rozpoznala. Cookies môžu byť použité pre zber, ukladanie a zdieľanie kusov informácii o Vašej aktivite skrz webové stránky, zahŕňajúc stránky spoločnosti {company}.',
  'cookiesInfo.content.section1.paragraph2':
    'Cookies můžu byť použité pre dosiahnutie nasledovného:',
  'cookiesInfo.content.section1.paragraph3':
    '{company} používa cookies pre reláciu a perzistentné cookies.',
  'cookiesInfo.content.section1.paragraph4':
    'Cookies pre reláciu sa používajú pre identifikovanie konkrétnej návštevy našich Stránok. Tieto cookies vypršia po krátkom čase, alebo keď po navštívení našich Stránok zatvoríte internetový prehliadač. Tieto cookies používame na Vašu identifikáciu počas jednej relácie prehliadača, napríklad potom čo sa na naše Stránky prihlásite.',
  'cookiesInfo.content.section1.paragraph5':
    'Perzistentné cookies zostanú uložené vo Vašom zariadení dlhší čas, ktorý je špecifikovaný v konkrétnom cookie. Tieto cookies používame vtedy, keď Vás potrebujeme identifikovať naprieč dlhším časovým obdobím. Perzistentné cookie používame napríklad pre uloženie nastavenia Vášho zvoleného jazyka.',
  'cookiesInfo.content.section2':
    'Ako používajú cookies spoločnosti tretích strán na Stránkach spoločnosti {company}?',
  'cookiesInfo.content.section2.paragraph1':
    'Spoločnosti tretích strán ako analytické a reklamné spoločnosti typicky zbierajú dáta na anonymnej báze. Tieto informácie môžu použiť na to, aby si vypracovali profil Vašich aktivít na Stránkach spoločnosti {company} a iných stránkach, ktoré ste navštívili.',
  'cookiesInfo.content.section3': 'Aké máte možnosti ohľadom cookies?',
  'cookiesInfo.content.section3.paragraph1':
    'Ak sa Vám nepáči myšlienka použitia všetkých cookies, alebo cookies nejakého typu, môžete upraviť nastavenia Vášho internetového prehliadača, kde môžete vymazať vytvorené cookies a nastaviť, aby nové cookies sa neukladali. Pre viac informácii ako to urobiť, navštívte stránky alebo dokumentáciu Vášho internetového prehliadača.',
  'cookiesInfo.content.section3.paragraph2':
    'Radi by sme Vás však upozornili na to, že je možné, že po zmazaní, alebo zakázaní ukladania cookies, nebudete môcť využívať všetku funkcionalitu, ktorú Vám naše stránky ponúkajú, nebudete môcť uložiť Vaše nastavenia a niektoré z našich stránok sa nemusia zobraziť korektne.',
  'cookiesInfo.content.section4':
    'Kde by som mohol nájsť viac informácii o cookies?',
  'cookiesInfo.content.section4.paragraph1':
    'Viac informácii o cookies môžete nájsť, ak navštívite nasledujúce stránky tretích strán:',
  'cookiesInfo.content.section4.list1.item1.link1': 'HTTP cookie – Wikipédia',
  'cookiesInfo.content.section4.list1.item1.link1.href':
    'https://sk.wikipedia.org/wiki/HTTP_cookie',
  'cookiesInfo.content.section4.list1.item2.link1':
    'Používanie cookies v súlade s GDPR | Podnikajte.sk',
  'cookiesInfo.content.section4.list1.item2.link1.href':
    'https://www.podnikajte.sk/zakonne-povinnosti-podnikatela/pouzivanie-cookies-gdpr',
  'cookiesInfo.content.section4.list1.item3.link1': 'Cookies | Európska únia',
  'cookiesInfo.content.section4.list1.item3.link1.href':
    'https://europa.eu/european-union/abouteuropa/cookies_sk',
  'cookiesInfo.popup.link': 'viac informácii',
  'cookiesInfo.popup.text':
    'Tieto stránky používajú cookies, ďalším prehliadaním stránok súhlasíte s ich používaním.',
  'cookiesInfo.title': 'Pravidlá používania cookies',
  'cookiesInfo.titleText':
    'Tieto stránky, sincersoft.com ("Stránky"), sú spravované spoločnosťou Sincersoft.',
  'copyright.info': '© {year} Sincersoft s.r.o. Všetky práva vyhradené.',
  'home.aboutUs.logoText': 'Čo o nás povedali',
  'home.aboutUs.textAD':
    'V Sincersofte sme našli partnera pre vývoj, ktorý má rovnaký prístup v "orientovaní sa na zákazníka" a "kvalitu" ako my. To bol pravdepodobne dôvod prečo zaintegrovanie Sincersoftu do rôznych projektov šlo tak hladko - bez ohľadu na to, či sa k našim tímom pripojili jednotliví vývojári, alebo či celková zodpovednosť za vývoj frontendu v príslušnom projekte bola prenesená na Sincersoft. Koniec koncov, nie len my ale aj naši zákazníci sú veľmi spokojní s výsledkami našej spoločnej práce - a to je v konečnom dôsledku to najdôležitejšie.',
  'home.aboutUs.textJK':
    'S Jurajem jsem spolupracoval více než 2 roky a vždy jsem byl s odevzdávanou prací spokojen. Juraj je podle mého názoru zkušený vývojář klientské části webových aplikací (JavaScriptu, React), na němž oceňuji především jeho organizovaný přístup k práci, pečlivost a spolehlivost.',
  'home.aboutUs.textMV':
    'Spolupráci se společností Sincersoft můžu jedině doporučit. Samozřejmostí je vysoká profesionalita a důraz na detail. Součástí dodávky jsou automaticky funkční testy a přehledná dokumentace.\n' +
    '\n' +
    'Celkově je odvedená práce na špičkové úrovni. Neméně důležitým bodem spolupráce je komunikace, a ta v tomto případě probíhá příkladně a ke spokojenosti všech zůčastněných.\n' +
    '\n' +
    'Vždy, když naše společnost potřebuje využít externí kapacity na vývoj frontendu, tak je první volbou společnost Sincersoft.',
  'home.aboutUs.textSF':
    'Spolupráca s firmou Sincersoft je pre nás pozitívnou skúsenosťou. Náš backend tím v Bratislave, frontend tím v Kežmarku a zákazník v Čechách dokázali efektivne spolupracovať a postupovať v projekte na diaľku tak, aby sme spoločne splnili termín dodania. Ako veľký benefit firmy Sincersoft vnímam ich záujem o celý projekt a snahu hľadať najlepšie riešenia v každej vrstve aplikácie. Firmu Sincersoft hodnotíme ako spoľahlivú, kreatívnu a flexibilnú. Aj preto sa veľmi tešíme na ďalšie spoločné projekty.',
  'home.clients.logo': 'S kým spolupracujeme',
  'home.company.numbers.logo': 'Naša spoločnosť v číslach',
  'home.company.numbers.yearsOfExperience': 'Rokov skúseností',
  'home.company.numbers.clients': 'Spokojných klientov',
  'home.company.numbers.projects': 'Projektov',
  'home.company.numbers.projectsHours': 'Odpracovaných hodín na projektoch',
  'home.company.numbers.pipelines':
    'Minút strávených v automatizovaných procesoch',
  'home.company.numbers.countries': 'Zahraničných projektov',
  'home.projects.logoText': 'Čo máme za sebou',
  'home.world.map.logo': 'Kde už poznajú našu prácu',
  'home.projects.project.haidi.headline':
    'ReactJS aplikácia pre zobrazovanie výsledkov spracovania (textových) dokumentov.',
  'home.projects.project.haidi.name': 'HAIDi',
  'home.projects.project.haidi.text':
    'Implementovali sme zaujímavé komponenty ako napríklad radenie a filtrovanie v grid-och, rôzne typy filtrovacích komponentov, generované formuláre a rozvrhnutia stránok podľa statických definičných súborov (schém), filtrovateľné reporty, interaktívne dokumenty vyrenderované z (nie HTML) markup-u a mnoho iných. Správanie väčšiny komponentov je konfigurovateľné pomocou statických konfiguračných súborov, čo umožňuje, aby HAIDi mohla byť viacúčelovou aplikáciou.',
  'home.projects.project.badger.headline':
    'ReactJS chatovacia aplikácia (s AI robotom na strane servera) – príprava pre doplnok (widget) do internetového prehliadača umožňujúci chatovanie s AI robotom.',
  'home.projects.project.badger.name': 'Badger Face',
  'home.projects.project.badger.text':
    'Popri jednoduchých textových správach, užívateľske rozhranie umožňuje odoslanie súborov, alebo nahranie a odoslanie hlasovej správy a taktiež je možné odoslať hodnotiacu spätnú väzbu k správe poslanej robotom. Komponent histórie konverzácie zobrazuje rôzne typy správ, ako napríklad súbor na stiahnutie, obohatenú správu k linke a správu s ovládacími prvkami pre odoslanie štrukturovanej odpovede. Pre komunikáciu medzi backendom a frontendom bolo použité WebSocket API.',
  'home.projects.project.pigeonStandards.headline':
    'ReactJS a React Native aplikácia zobrazujúca štandardy plemien domácich holubov.',
  'home.projects.project.pigeonStandards.name': 'Vzorník plemien holubov',
  'home.projects.project.pigeonStandards.text':
    'Implementovali sme kompletnú aplikáciu (backendovú aj frontendovú časť). Zdrojové dáta vo formáte LaTeX (štrukturovaný formát, ktorý je vhodnejší pre úpravy človekom - pisateľom štandardu holuba) je spracovaný a prevedený do json formátu (vhodnejší pre spracovanie frontendovou aplikáciou). Obe aplikácie, webová aj natívna, používajú rovnaký základ, aby sa zamedzilo duplikovaniu zdrojového kódu a funkcionality.',
  'home.projects.project.salusPopuli.headline':
    'Responzívna ReactJS aplikácia pre prístup a správu investičného fondu.',
  'home.projects.project.salusPopuli.name': 'Salus Populi',
  'home.projects.project.salusPopuli.text':
    'Aplikácia je postavená na responzívnom dizajne, keďže požiadavka od zákazníka bolo umožniť používanie na desktope a zároveň aj v mobilnej verzii webového prehliadača. Aj v tejto aplikácii sme zúročili naše skúsenosti s tabuľkovým komponentom, v ktorom je možné zobrazené záznamy zoradiť či filtrovať. Aplikácia umožňuje prístup užívateľom s rôznymi rolami, každej z nich sa tak zobrazí príslušná agenda. Prehľadné zobrazenie dát v grafe je tiež samozrejmosťou, kde v tooltip-e (funguje dobre aj v mobilnom zobrazení) vidí užívateľ aktuálny stav svojich investícii aj s príslušnými zmenami. Okrem samotnej aplikácie sme pripravili aj Investičnú kalkulačku ako samostatný widget, čo umožňuje zákazníkovi zakomponovať kalkulačku do svojich prezentačných stránok.',
  'home.skills.description':
    'Základené piliere webového frontendu - HTML, CSS, JavaScript, no hlavne sa špecializujeme na frontend postavený na React-e.',
  'home.skills.logo': 'Čo ovládame',
  'home.tools.description':
    'Snažíme sa pri vývoji používať aktuálne verzie podporných nástrojov.',
  'home.tools.logo': 'Čo používame',
  'home.title': 'Úprimne',
  'home.titleItemModernDesign': 'Moderný vzhľad',
  'home.titleItemReliableFunctionality': 'Spoľahlivá funkčnosť',
  'home.titleText':
    'Ponúkame Vám naše služby vývoja frontendu pre webové aplikácie, tak aby ste boli veľmi spokojní.',
  'home.titleSubText': 'Čo máme za sebou',
  'home.work.logoText': 'Ako pracujeme',
  'home.work.parAO': '"Práca na diaľku je budúcnosťou spôsobu práce."',
  'home.work.parMD':
    '"Technológie dneška umožňujú ľuďom byť pripojený hocikedy, hocikde, s hocikým na celom svete, cez skoro ľubovoľné zariadenie. Toto dramaticky mení spôsob ako ľudia pracujú, umožňuje to spoluprácu 24 hodín 7 dní v týždni s kolegami, ktorí sú roztrúsení naprieč časovými zónami, krajinami a kontinetami."',
  'home.work.parSRB':
    '"U nás radi dávame ľuďom možnosť pracovať odkiaľ chcú, pretože vieme, že majú "drive" a kvalitu pracovať excelentne bez ohľadu na to či sú za svojím pracovným stolom, alebo u seba v kuchyni. ... Ak im poskytnete vhodné technológie aby ste s nimi boli v kontakte, udržiavate pravidelnú komunikáciu a tiež správnu rovnováhu medzi prácou na diaľku a prácou z kancelárie, ľudia budú motivovaní pracovať zodpovedne, rýchlo a v najvyššej kvalite."',
  'home.work.parHS':
    '"Celá diskusia okolo práce na diaľku je typicky o tom ako je práca na diaľku iná namiesto toho, aby sa viedla o úžasných nástrojoch, ktoré sú nám k dispozícii, a ktoré môžu tími pracujúci na diaľku, či v kancelárii využívať. Máme možnosť byť oveľa slobodnejší v našom prostredí pri porovnaní s časom, keď sme museli byť v kancelárii, či škole 40 hodín týždenne."',
  'home.work.parKB':
    '"Chcete mať prístup k talentom hocikde, alebo len na špecifickom trhu? Ak je odpoveďou hocikde, mali by ste byť prinajmenšom otvorení myšlienke práce na diaľku - otvára to dvere k pritiahnutiu a udržaniu talentov z celého sveta, doslovne aj obrazne."',
  'home.work.parSM':
    '"Práca na diaľku je neuveriteľné pozvanie byť naozaj dobrým v budovaní inkluzívnej kultúry, kde je široká rozmanitosť rôznych typov ľudí a budovať kultúru, kde každý sa cíti zapojený a každý zažíva neustály osobnostný rast a vývoj na pravidelnej báze. Je to výzva a nie ľahká. Ale biznis všeobecne nie je ľahký."',
  'home.work.parCL':
    '"Pre lídra spoločnosti neexistuje niečo také ako nadmerná komunikácia a pre spoločnosť, ktorá pracuje na diaľku to platí dvojnásobne. Keďže ľudia sa nestretávajú osobne tvárou tvár, informácie sa šíria inakšie a preto lídri potrebujú vyvinúť väčšie úsilie na to, aby rozniesli nejakú správu."',
  'home.work.secondParagraph':
    'Pre prácu na diaľku ("remote work") sme v našej firme nastavení od úplného začiatku. Je to jedna z našich charakteristík a preto sa neustále snažíme hľadať možnosti ako pracovať na diaľku efektívnejšie. Popri tom sa snažíme využívať všetky benefity, ktoré z toho plynú ako na strane spolupráce s našimi klientmi tak aj v rámci nášho tímu.',
  'home.sliding.button.backToTop': 'Späť na začiatok stránky',
  'home.service.detail': 'Zobraziť viac',
  'navigation.aboutUs': 'O nás',
  'navigation.contact': 'Kontakt',
  'navigation.blog': 'Blog',
  'navigation.home': 'Domov',
  'navigation.references': 'Referencie',
  'navigation.services': 'Služby',
  'page.aboutUs.description':
    'Charakteristika, história a tím spoločnosti Sincersoft.',
  'page.aboutUs.title': 'O nás',
  'page.blog.title': 'Blog',
  'page.blog.description': 'Blog spoločnosti Sincersoft.',
  'page.contacts.description': 'Kontakty spoločnosti Sincersoft.',
  'page.contacts.title': 'Kontakt',
  'page.cookiesInfo.description': 'Informácie o pravidlách používania cookies.',
  'page.cookiesInfo.title': 'Pravidlá používania cookies',
  'page.home.description':
    'Sincersoft je malá začínajúca spoločnosť poskytujúca služby vývoja software (frontend) prevažne webových aplikácii a zameriava sa najmä na React.',
  'page.references.description':
    'Referencie a projekty spoločnosti Sincersoft.',
  'page.references.title': 'Referencie',
  'page.services.title': 'Vyberte si službu, akú potrebujete',
  'page.services.description': 'Služby ponúkané spoločnosťou Sincersoft.',
  'reference.itemButtonText': 'detaily projektu',
  'reference.preview.showMore': 'Zobraziť viac',
  'reference.project.badger.images.chatLayout':
    'Rozvrhnutie chatovacej aplikácie',
  'reference.project.badger.images.chatWithTutorial':
    'Rozvrhnutie chatovacej aplikácie s konzultačným panelom, ktorý ovláda robot',
  'reference.project.badger.images.dragAndDropFileUpload':
    'Nahrávanie súborov cez "drag and drop"',
  'reference.project.badger.images.messageFeedback':
    'Výber spatnej väzby k správe od robota',
  'reference.project.badger.images.linkSnippetMessage':
    'Obohatená správa k linke v histórii chatu',
  'reference.project.badger.images.fileMessage':
    'Súbor na stiahnutie v histórii chatu',
  'reference.project.badger.images.imageMessage': 'Obrázok v histórii chatu',
  'reference.project.badger.images.messageWithFeedback':
    'Správa so spätnou väzbou v histórii chatu',
  'reference.project.badger.images.switchingAvatar':
    'Funkcionalita na prepnutie avatara (príkaz pre prepnutie je poslaný na pozadí)',
  'reference.project.badger.images.inputsInMessage':
    'Ovládacie prvky v histórii chatu',
  'reference.project.badger.images.multipleTypeOfInputs':
    'Užívateľské rozhranie umožňuje poslať správy rôzneho typu (text, hlas, súbor)',
  'reference.project.haidi.images.appLayout': 'Rozvrhnutie aplikácie',
  'reference.project.haidi.images.caseDetail':
    'Rozvrhnutie detailu prípadu (objekt, ktorý zaobaľuje skupinu dokumentov)',
  'reference.project.haidi.images.documentLayout':
    'Rozvrhnutie detailu dokumentu',
  'reference.project.haidi.images.filterDetail': 'Panel so zvolenými filtrami',
  'reference.project.haidi.images.filteredReport':
    'Filtrované reporty (filtrované sú obe časti - tabuľka a graf)',
  'reference.project.haidi.images.listFiltered': 'Filtrovaný zoznam',
  'reference.project.haidi.images.renderedDocument':
    'Interaktívny dokument vyrenderovaný z (nie HTML) markup-u',
  'reference.project.haidi.images.reportWithFilter':
    'Reporty môžu byť filtrované',
  'reference.project.haidi.images.sortableAndFilterableGrid':
    'Tabuľky umožňujú dáta stránkovať, radiť a filtrovať',
  'reference.project.haidi.images.timelines':
    'Dáta časovej osi prezentované v grafe a v tabuľke',
  'reference.project.haidi.images.generatedForm':
    'Formulár generovaný zo statickej konfigurácie (schémy)',
  'reference.project.pigeonStandards.images.pigeonStandardDetail':
    'Detail štandardu holuba vo webovej aplikácii',
  'reference.project.pigeonStandards.images.pigeonStandardDetailMobile':
    'Detail štandardu holuba v natívnej aplikácii',
  'reference.project.salusPopuli.images.overview':
    'Úvodná stránka zobrazuje klientovi to najpodstatnejšie a umožňuje mu vykonať výber, alebo vklad do fondu',
  'reference.project.salusPopuli.images.clients':
    'Makléri majú vždy prehľad nad svojimi klientmi',
  'reference.project.salusPopuli.images.graph':
    'V grafe tranzakcií klient prehľadne vidí aktuálnu hodnotu svojho účtu aj so zmenami v čase',
  'reference.project.salusPopuli.images.invitations':
    'Prehľadné zobrazenie pozvaných klientov je tiež súčasťou aplikácie',
  'reference.project.salusPopuli.images.login':
    'Štandardný prihlasovací formulár',
  'reference.project.salusPopuli.images.modeler':
    'Každý klient si pri registrácii môže naživo namodelovať odhadovaný vývoj svojich investícii na základe svojich predpokladaných vkladov',
  'reference.project.salusPopuli.images.reg_questionnaire':
    'Proces registrácie je doplnený interaktívnou prehliadkou, v ktorej aplikácia užívateľa prevedie jednotlivými registračnými krokmi',
  'reference.project.salusPopuli.images.transaction':
    'Prehľadný tabuľkový (grid) komponent v aplikácii samozrejme nechýba',
  'reference.title': 'Čo máme za sebou',
  'reference.titleText': 'Naše projekty hovoria za nás',
};
