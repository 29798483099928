import {Box} from '@chakra-ui/react';
import React from 'react';

import LanguageButton from './LanguageButton';

interface OwnProps {
  changeLocale: (locale: string) => void;
  itemClass?: string;
  languages: any[];
  selectedLocale: string;
}

type Props = OwnProps;

const LanguageSwitch: React.FC<Props> = (props) => {
  const {itemClass, languages, selectedLocale, changeLocale} = props;
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {languages &&
        languages.map((lang) => (
          <Box key={lang.locale}>
            <LanguageButton
              selectedLocale={selectedLocale}
              changeLocale={changeLocale}
              itemClass={itemClass}
              lang={lang}
            />
          </Box>
        ))}
    </Box>
  );
};

LanguageSwitch.displayName = 'LanguageSwitch';

export default LanguageSwitch;
