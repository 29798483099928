/**
 * @type {{makeCancelable: (function(*): {cancel(): void, promise: Promise<any>})}}
 * @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 */
const PromiseUtils = {
  makeCancelable: (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
      promise
        .then(
          (val) => (hasCanceled_ ? reject({isCanceled: true}) : resolve(val)),
          (error) =>
            hasCanceled_ ? reject({isCanceled: true}) : reject(error),
        )
        .catch();
    });

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true;
      },
    };
  },
};

export default PromiseUtils;
