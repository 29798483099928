export const I18N_REDUCER_STATE_ID = 'I18N_REDUCER_STATE_ID';

export const LOCALE_SK = 'sk';
export const LOCALE_EN = 'en';

export const DEFAULT_LOCALE = LOCALE_EN;

export const USED_LANGUAGES = [
  {locale: LOCALE_SK, label: 'slovenčina', short: 'SK'},
  {locale: LOCALE_EN, label: 'English', short: 'EN'},
];

export const COOKIE_LOCALE = 'locale';
