class AppConfig {
  static get CAPTCHA_SITE_KEY() {
    return '6LfnoKcUAAAAAI3Sc5GGQech3Z1s5DUYy_MxlAvK';
  }

  static get BACKEND_SERVICE_URL() {
    return '/backend';
  }

  static get STRAPI_SERVICE_URL() {
    return '/strapiApi';
  }

  static get STRAPI_ASSETS_URL() {
    return '/strapiAssets';
  }

  static get general() {
    const domainName =
      import.meta.env.VITE_APP_ENV === 'staging'
        ? 'https://dev.sincersoft.com'
        : 'https://sincersoft.com';
    return {
      domainName: domainName,
      companyName: 'Sincersoft',
    };
  }

  static get services() {
    return {
      // #reCAPTCHA
      captchaVerifyToken: `${AppConfig.BACKEND_SERVICE_URL}/captcha_verify_token.php`,

      // #Strapi BE
      articles: `${AppConfig.STRAPI_SERVICE_URL}/articles`,
      categories: `${AppConfig.STRAPI_SERVICE_URL}/categories`,
      shortServices: `${AppConfig.STRAPI_SERVICE_URL}/short-services`,
      longServices: `${AppConfig.STRAPI_SERVICE_URL}/long-services`,
      services: `${AppConfig.STRAPI_SERVICE_URL}/services`,
      references: `${AppConfig.STRAPI_SERVICE_URL}/references`,
      about: `${AppConfig.STRAPI_SERVICE_URL}/about`,
      pictures: `${AppConfig.STRAPI_ASSETS_URL}`,
      pricingInfo: `${AppConfig.STRAPI_SERVICE_URL}/pricing-info`,
    };
  }

  static get firebase() {
    return {
      apiKey: 'AIzaSyDnbrGH-CNGr7UWEiaQ6MpWEQF5EUxtRLk',
      authDomain: 'sincersoft-web.firebaseapp.com',
      projectId: 'sincersoft-web',
    };
  }

  static get gTag() {
    return {
      // the same one, that is used in index.html
      gaMeasurementId: 'UA-145182779-1',
    };
  }
}

export default AppConfig;
