import cookie from 'cookie';

const DEFAULT_COOKIE_OPTIONS = {
  maxAge: 30 * 24 * 60 * 60 * 1000,
};

const CookiesUtils = {
  setCookie(key, value, options = {}) {
    const expiredDate = new Date();
    const maxAge = options.maxAge || DEFAULT_COOKIE_OPTIONS.maxAge;
    // compute expired date according to maxAge option
    expiredDate.setTime(expiredDate.getTime() + maxAge);
    document.cookie = cookie.serialize(key, value, {
      expires: expiredDate,
      ...DEFAULT_COOKIE_OPTIONS,
      ...options,
      path: '/',
    });
  },

  getCookie(key, options) {
    const parsedCookies = cookie.parse(document.cookie, options);
    return parsedCookies[key];
  },
};

export default CookiesUtils;
