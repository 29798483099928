import {
  ADD_LOADER,
  TOGGGLE_DISPLAY_PRICING_INFO,
  REMOVE_LOADER,
} from './layout_action_types';

export const initialState = {
  loadersCount: 0,
  isFormPricingInfoDisplayed: false,
};

export function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case ADD_LOADER:
      newState = {
        ...state,
        loadersCount: state.loadersCount + 1,
      };
      break;
    case REMOVE_LOADER:
      newState = {
        ...state,
        loadersCount: Math.max(state.loadersCount - 1, 0),
      };
      break;
    case TOGGGLE_DISPLAY_PRICING_INFO:
      newState = {
        ...state,
        isFormPricingInfoDisplayed: !state.isFormPricingInfoDisplayed,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
}
