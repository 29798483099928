import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './PageTopLayout.module.less';

interface OwnProps {}

type Props = OwnProps;

const PageTopTitleText: React.FC<Props> = ({idText}) => (
  <span className={styles.siTopTitleText}>
    <FormattedMessage id={idText} />
  </span>
);

PageTopTitleText.displayName = 'PageTopTitleText';

PageTopTitleText.propTypes = {
  idText: PropTypes.string.isRequired,
};

export default PageTopTitleText;
