import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './PageTopTitleItems.module.less';

interface OwnProps {}

type Props = OwnProps;

const PageTopTitleItems: React.FC<Props> = ({titleItemsIds}) => (
  <ul className={styles.siPageTopTitleItemsUl}>
    {titleItemsIds.map((item) => (
      <li key={item}>
        <h2 className={styles.siPageTopTitleItemsText}>
          <FormattedMessage id={item} />
        </h2>
      </li>
    ))}
  </ul>
);

PageTopTitleItems.displayName = 'PageTopTitleItems';

PageTopTitleItems.propTypes = {
  titleItemsIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PageTopTitleItems;
