import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './PageTopTitle.module.less';

interface OwnProps {}

type Props = OwnProps;

const PageTopTitle: React.FC<Props> = ({idText, children}) => (
  <div className={styles.siPageTopTitle}>
    <h2 className={styles.siTopTitle}>
      <FormattedMessage id={idText} />
    </h2>
    {children}
  </div>
);

PageTopTitle.displayName = 'PageTopTitle';

PageTopTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  idText: PropTypes.string.isRequired,
};

PageTopTitle.defaultProps = {
  children: null,
};

export default PageTopTitle;
