import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {HOME_TOP_ID} from 'src/js/containers/home/home_constants';

import styles from './PageTopLayout.module.less';

const pageTopLayoutId = HOME_TOP_ID;

interface OwnProps {}

type Props = OwnProps;

const PageTopLayout: React.FC<Props> = ({children, className, hasOverlay}) => (
  <div className={classNames(className, styles.siTop)} id={pageTopLayoutId}>
    <div className={styles.siTopTextWrapper}>{children}</div>
    {hasOverlay ? <div className={styles.siTopOverlay} /> : null}
  </div>
);

PageTopLayout.displayName = 'PageTopLayout';

PageTopLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string.isRequired,
  hasOverlay: PropTypes.bool,
};

PageTopLayout.defaultProps = {
  hasOverlay: false,
};

export default PageTopLayout;
