import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';

// if (!import.meta.env.SSR) {
const store = createStore(
  rootReducer,
  window.__PRELOADED_STATE__,
  import.meta.env.DEV
    ? composeWithDevTools(applyMiddleware(thunkMiddleware))
    : applyMiddleware(thunkMiddleware),
);
delete window.__PRELOADED_STATE__;
// }

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
