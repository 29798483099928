import {combineReducers} from 'redux';

import {CONTACTS_REDUCER_STATE_ID} from './containers/contacts/contacts_constants';
import {contactsReducer} from './containers/contacts/contacts_reducer';
import {I18N_REDUCER_STATE_ID} from './containers/i18n/i18n_constants';
import {i18nReducer} from './containers/i18n/i18n_reducer';
import {LAYOUT_STATE_ID, reducer as layout} from './containers/layout';

const rootReducer = combineReducers({
  [CONTACTS_REDUCER_STATE_ID]: contactsReducer,
  [I18N_REDUCER_STATE_ID]: i18nReducer,
  [LAYOUT_STATE_ID]: layout,
});

export default rootReducer;
