import {CHANGE_LOCALE} from './i18n_action_types';
import {USED_LANGUAGES, DEFAULT_LOCALE} from './i18n_constants';

export const initialState = {
  selectedLocale: DEFAULT_LOCALE,
  languages: USED_LANGUAGES,
};

export function i18nReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case CHANGE_LOCALE:
      newState = {
        ...state,
        selectedLocale: action.payload.locale,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
}
