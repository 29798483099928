import PropTypes from 'prop-types';
import React, {Component} from 'react';

import PromiseUtils from 'src/js/common/utils/promise_utils';

import LoadingContainer from '../loading/LoadingContainer';

class AsyncImport extends Component {
  static toggleFoucClass() {
    const root = document.getElementById('reactApp');
    if (root) {
      root.classList.toggle('fouc');
    }
  }

  constructor() {
    super();

    this.state = {
      component: null,
    };
    this.cancelableLoad = null;
  }

  UNSAFE_componentWillMount() {
    AsyncImport.toggleFoucClass();
  }

  componentDidMount() {
    const {load} = this.props;
    this.cancelableLoad = PromiseUtils.makeCancelable(load());
    this.cancelableLoad.promise
      .then((component) => {
        setTimeout(() => AsyncImport.toggleFoucClass(), 0);
        this.setState(() => ({
          component: component.default,
        }));
      })
      .catch(() => {});
  }

  componentWillUnmount() {
    if (this.cancelableLoad) {
      this.cancelableLoad.cancel();
    }
  }

  render() {
    const {children} = this.props;
    const {component} = this.state;
    return children(component);
  }
}

AsyncImport.displayName = 'AsyncImport';

AsyncImport.propTypes = {
  load: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const asyncRoute = (importFunc) => {
  const WrappedComponent = (props) => (
    <AsyncImport load={importFunc}>
      {(Component) => {
        return Component === null ? (
          <LoadingContainer />
        ) : (
          <Component {...props} />
        );
      }}
    </AsyncImport>
  );
  WrappedComponent.displayName = 'WrappedComponent';
  return WrappedComponent;
};

export default asyncRoute;
