import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {To, useLocation, useNavigate} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

import {PAGE_LAYOUT_ROUTE_CONTACTS} from 'src/js/containers/layout';
import {NavigationItem} from 'src/js/containers/navigation/navigation_types.ts';

import LanguageSwitchContainer from './LanguageSwitchContainer';

const WRITE_US_ROUTE = {
  pathname: PAGE_LAYOUT_ROUTE_CONTACTS,
  hash: '#write-us',
};

interface OwnProps {
  navigationsData: NavigationItem[];
}

type Props = OwnProps;

const NavigationMobileMenu: React.FC<Props> = (props) => {
  const {navigationsData} = props;
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const handleOnClick = (routeName: To) => {
    navigate(`${routeName}`);
  };

  const linkTo = {pathname: WRITE_US_ROUTE.pathname, hash: WRITE_US_ROUTE.hash};

  const isMenuItemActive = (path: string) => {
    if (path === '/') {
      // handle the home route separately bacause its matching all paths
      return pathname === '/';
    }
    return pathname.startsWith(path);
  };

  return (
    <div>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<img src={'/burgerMenu.svg'} alt={'Menu icon'} />}
          variant={'outline'}
          right={'15px'}
        />
        <MenuList w={'280px'}>
          {navigationsData.map((item) => {
            return (
              <MenuItem
                key={item.label}
                onClick={() => handleOnClick(item.route)}
                bgColor={isMenuItemActive(item.route) ? 'brand.900' : 'white'}
                color={isMenuItemActive(item.route) ? 'white' : 'brand.500'}
                _hover={{
                  bgColor: 'brand.500',
                  color: 'white',
                }}
              >
                <FormattedMessage id={item.label} />
              </MenuItem>
            );
          })}
          <MenuDivider height={'1px'} bgColor={'brand.500'} />
          <Box
            display={'flex'}
            flexDir={'column'}
            alignItems={'center'}
            gap={1}
          >
            <Box
              display={'flex'}
              alignSelf={'center'}
              justifyContent={'center'}
            >
              <MenuItem _hover={{bgColor: 'white'}} m={0} p={0}>
                <Link
                  as={HashLink}
                  to={linkTo}
                  _hover={{textDecoration: 'none'}}
                >
                  <Text
                    py={2}
                    px={4}
                    variant={'filled'}
                    color={'white'}
                    borderRadius={'6px'}
                    textDecoration={'none'}
                    bgColor={'brand.500'}
                    _hover={{color: 'brand.900'}}
                  >
                    <FormattedMessage id={'contact.writeUs'} />
                  </Text>
                </Link>
              </MenuItem>
            </Box>
            <LanguageSwitchContainer />
          </Box>
        </MenuList>
      </Menu>
    </div>
  );
};

NavigationMobileMenu.displayName = 'NavigationMobileMenu';

export default NavigationMobileMenu;
