import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import LocalizationProvider from 'src/js/containers/i18n/components/LocalizationProvider.tsx';
import PageLayoutContainer from 'src/js/containers/layout/components/PageLayoutContainer';
import store from 'src/js/store.ts';

import './styles/dependencies.less';
import './styles/app.module.less';

const queryClient = new QueryClient();

//TODO: move to new file - theme.ts
const theme = extendTheme({
  fonts: {
    body: 'Ubuntu Regular, sans-serif',
    heading: 'Ubuntu Bold',
    mono: 'Ubuntu Regular, monospace',
  },
  colors: {
    brand: {
      50: '#F1F9FD',
      100: '#D4FCFD',
      200: '#A9F4FC',
      300: '#7DE2F6',
      400: '#5BCCED',
      500: '#29ABE2',
      600: '#1D86C2',
      700: '#1465A2',
      800: '#0D4883',
      900: '#07336C',
    },
  },
});
// Chakra with default Theme
const app = (
  <ChakraProvider theme={theme}>
    <HelmetProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider>
            <BrowserRouter>
              <PageLayoutContainer />
            </BrowserRouter>
          </LocalizationProvider>
        </QueryClientProvider>
      </Provider>
    </HelmetProvider>
  </ChakraProvider>
);
const element = document.getElementById('reactApp');
const root = createRoot(element);
root.render(app);
