import AppConfig from 'src/js/config/app_config';

const AnalyticsUtils = {
  sendPageView(pagePath) {
    if (gtag) {
      gtag('config', AppConfig.gTag.gaMeasurementId, pagePath);
    }
  },

  sendEvent(action, eventData) {
    if (gtag) {
      gtag('event', action, eventData);
    }
  },
};

export default AnalyticsUtils;
