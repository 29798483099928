import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useIntl} from 'react-intl';

import TranslateUtils from 'src/js/common/utils/translate_utils';
import AppConfig from 'src/js/config/app_config';

interface OwnProps {
  description?: string;
  routeName?: string;
  title?: string;
  children?: React.ReactNode;
  seoExtraItems?: React.ReactNode;
}

type Props = OwnProps;

const DynamicPageMetaInfo: React.FC<Props> = ({
  description = '',
  routeName = '',
  title = '',
  seoExtraItems,
  children,
}) => {
  const intl = useIntl();
  const descriptionText = TranslateUtils.translate(description, intl);
  const titleText = TranslateUtils.translate(title, intl);
  const url = `${AppConfig.general.domainName}${routeName}`;
  return (
    <Helmet
      defaultTitle={AppConfig.general.companyName}
      titleTemplate={`%s - ${AppConfig.general.companyName}`}
    >
      {title ? <title>{titleText}</title> : null}
      <meta name={'description'} content={descriptionText} />
      <link rel={'canonical'} href={url} />
      {seoExtraItems}
      {children}
    </Helmet>
  );
};

DynamicPageMetaInfo.displayName = 'DynamicPageMetaInfo';

export default DynamicPageMetaInfo;
