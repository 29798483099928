import React from 'react';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';

import {en} from 'src/js/config/en';
import {sk} from 'src/js/config/sk';

import {initLocale} from '../i18n_actions';
import {I18N_REDUCER_STATE_ID} from '../i18n_constants';

class LocalizationProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    /* eslint-disable react/destructuring-assignment */
    this.props.initLocale();
    /* eslint-enable react/destructuring-assignment */
  }

  render() {
    const {selectedLocale, children} = this.props;
    const messages = selectedLocale === 'en' ? en : sk;
    return (
      <IntlProvider key={selectedLocale} locale={'en'} messages={messages}>
        {children}
      </IntlProvider>
    );
  }
}

LocalizationProvider.displayName = 'LocalizationProvider';

export default connect(
  (state) => ({
    selectedLocale: state[I18N_REDUCER_STATE_ID].selectedLocale,
  }),
  (dispatch) => ({
    initLocale: () => dispatch(initLocale()),
  }),
)(LocalizationProvider);
