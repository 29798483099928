import {Box, Button, Flex, Image, Link} from '@chakra-ui/react';
import classNames from 'classnames';
import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';

import TranslateUtils from 'src/js/common/utils/translate_utils.ts';
import {PAGE_LAYOUT_ROUTE_CONTACTS} from 'src/js/containers/layout';
import {NavigationItem} from 'src/js/containers/navigation';
import LanguageSwitchContainer from 'src/js/containers/navigation/components/LanguageSwitchContainer';
import styles from 'src/js/containers/navigation/components/Navigation.module.less';
import NavigationMobileMenu from 'src/js/containers/navigation/components/NavigationMobileMenu';
import {NAVIGATION_MENU_ID} from 'src/js/containers/navigation/navigation_constants';

const WRITE_US_ROUTE = {
  pathname: PAGE_LAYOUT_ROUTE_CONTACTS,
  hash: '#write-us',
};

interface Props {
  leftNavigationData: NavigationItem[];
  rightNavigationData: NavigationItem[];
}

const Navigation: React.FC<Props> = (props) => {
  const {leftNavigationData, rightNavigationData} = props;
  const intl = useIntl();
  const translatedHomeTitle = TranslateUtils.translate('navigation.home', intl);

  const handleNavClick = () => {
    window.scrollTo(0, 0);
  };

  const mobileNavigationData = useMemo(
    () => leftNavigationData.concat(rightNavigationData),
    [leftNavigationData, rightNavigationData],
  );

  const linkTo = {pathname: WRITE_US_ROUTE.pathname, hash: WRITE_US_ROUTE.hash};

  return (
    <Box position={'relative'}>
      <Box className={styles.siNavigation} id={NAVIGATION_MENU_ID}>
        <Box
          display={{base: 'none', xl: 'flex'}}
          className={styles.siNavigationMenu}
          flexDirection={'row'}
          w={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Flex gap={5}>
            {leftNavigationData.map((item) => (
              <NavLink
                onClick={handleNavClick}
                key={item.route}
                to={item.route}
                className={({isActive}) =>
                  isActive
                    ? classNames([
                        styles.siNavigationLinks,
                        styles.siNavigationActiveLinks,
                      ])
                    : styles.siNavigationLinks
                }
              >
                <FormattedMessage id={item.label} />
              </NavLink>
            ))}
          </Flex>
          <Box mx={'44px'} className={styles.siAppImg}>
            <NavLink to={'/'}>
              <Image
                title={translatedHomeTitle}
                draggable={'false'}
                src={'/sincersoft_logo.svg'}
                alt={'Sincersoft home'}
              />
            </NavLink>
          </Box>
          <Flex gap={5}>
            {rightNavigationData.map((item) => (
              <NavLink
                onClick={handleNavClick}
                key={item.route}
                to={item.route}
                className={({isActive}) =>
                  isActive
                    ? classNames([
                        styles.siNavigationLinks,
                        styles.siNavigationActiveLinks,
                      ])
                    : styles.siNavigationLinks
                }
              >
                <FormattedMessage id={item.label} />
              </NavLink>
            ))}
            {mobileNavigationData.length % 2 === 1 ? <Box w={'100px'} /> : null}
          </Flex>
          <div className={styles.siNavigationLanguage}>
            <LanguageSwitchContainer />
          </div>
          <Flex
            position={'absolute'}
            top={'74px'}
            right={'150px'}
            border={'4px solid white'}
            borderRadius={'md'}
          >
            <Link
              display={{base: 'none', xl: 'flex'}}
              as={HashLink}
              to={linkTo}
              _hover={{textDecoration: 'none'}}
            >
              <Button
                variant={'filled'}
                color={'white'}
                bgColor={'brand.500'}
                _hover={{textDecoration: 'none', color: 'brand.900'}}
              >
                <FormattedMessage id={'contact.writeUs'} />
              </Button>
            </Link>
          </Flex>
        </Box>
        <Box
          display={{base: 'flex', xl: 'none'}}
          w={'100%'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <NavLink to={'/'}>
            <Image
              title={translatedHomeTitle}
              draggable={'false'}
              src={'/sincersoft_logo.svg'}
              alt={'Sincersoft home'}
            />
          </NavLink>
          <Box>
            <NavigationMobileMenu navigationsData={mobileNavigationData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Navigation.displayName = 'Navigation';

export default Navigation;
