export const en = {
  'aboutUs.dev1Text':
    "The founder of the company worked for more than 12 years in Prague, where he also finished his doctoral study in Computer Science at the Faculty of Mathematics and Physics of Charles University. And thanks to that, most of our current clients are from Prague. After working as a software engineer for 10 years, and 5 years in front-end development, he decided to take advantage of these experiences and share them with others, especially as a mentor of company's employees.",
  'aboutUs.title': 'Who We Are',
  'aboutUs.titleText': 'Allow us, to introduce ourselves.',
  'aboutUs.char.charText':
    "We are a software company providing development services for web and mobile applications. For developing and maintaining applications, it is important for us to test, document and use high-quality developer tools in the sense of the idea 'First choose a good shovel, prepare your working environment and then start digging'. In addition to professional work, we are very concerned about human and Christian values within the work team, but also towards our clients and business partners. We pride ourselves on honest communication and work responsibility, respecting each other and wanting the best for others. That is why we want to create quality software that will make life easier for our clients.",
  'aboutUs.char.title': 'Characteristics of the company',
  'aboutUs.name.nameText':
    "When looking for the name of our company, we were thinking that the title, in addition to the professional focus itself, would also characterize our values that we consider important. We consider sincerity to be one of the most important values of cooperation, whether to our business partners or to each other. Therefore our name is based on the Latin translation of the word 'sinceritate' [sincheritate], the one that also the English word 'sincere' came from. However, as we had the intention of offering our company services to foreign clients as well, we decided to base the company name on English pronunciation of 'sincere' [sin’si (ǝ) r].",
  'aboutUs.name.nameAndText': 'Sincersoft - pronounce [Sin’si(ǝ)rsoft]',
  'aboutUs.name.nameTitle': 'The origin of the company name',
  'aboutUs.team.position.developer': 'Software developer',
  'aboutUs.team.position.owner': 'Founder, CEO, CTO, Lead developer',
  'aboutUs.team.title': 'Our team',
  'aboutUs.heading.weAre': 'We are',
  'aboutUs.heading.sincersoft': 'Sincersoft',
  'aboutUs.heading.message':
    'A software company providing development services for web and mobile applications.',
  'blog.ad.text':
    'We will suggest the most effective solution for your project.',
  'blog.ad.title':
    'Do you have a great idea for an app? Do not hesitate to contact us.',
  'blog.category': 'Categories',
  'callToAction.writeUs': 'TELL US ABOUT YOUR PROJECT',
  'contact.title': 'Contact',
  'contact.button': 'Contact us',
  'contact.writeUs': 'Write us',
  'contact.titleText': 'We will be happy to answer your questions.',
  'contact.form.law':
    'Company Sincersoft s.r.o. [private limited companies] is registered in the Commercial Register maintained by Prešov District Court, Entry no. 37460/P',
  'contact.form.message': 'Message',
  'contact.form.name': 'Name (company name)',
  'contact.form.submit': 'Submit',
  'contact.form.success': 'The email was sent successfully.',
  'contact.form.error':
    'There was an error sending the email. If the error is persisting, contact us at info@sincersoft.com',
  'contact.form.select': 'Budget',
  'contact.form.budgetLessThan': 'less than 5,000 €',
  'contact.form.budgetFiveToTen': '5,000 € - 10,000 €',
  'contact.form.budgetTenToThirty': '10,000 € - 30,000 €',
  'contact.form.budgetThirtyToHundred': '30,000 € - 100,000 €',
  'contact.form.budgetMoreThan': 'above 100,000 €',
  'contact.form.budgetDefault': 'Choose budget',
  'contact.form.title': 'Tell Us About Your Project',
  'contact.form.info':
    'Write us and we will contact you in 24 hours and suggest the next steps.',
  'contact.form.email.subject': 'Sincersoft contact form',
  'contact.form.phoneNumber': 'Phone number',
  'contact.info.billingAddress': 'billing address',
  'contact.info.billingInfo': 'Billing information',
  'contact.info.companyCountry': 'Slovak republic',
  'contact.info.companyPersonPosition': 'CEO, Sales',
  'contact.info.emailSecurityInfo':
    'For security reasons the email address is read-only',
  'contact.info.headquarters': 'Headquarters',
  'contact.info.mandatoryEmailAndMessageMessage':
    'Email address and message cannot be empty.',
  'contact.info.mandatoryEmailMessage': 'Email address cannot be empty.',
  'contact.info.mandatoryMessageMessage': 'Message cannot be empty.',
  'contact.info.mandatoryBudgetMessage': 'Please select a budget option.',
  'contact.info.office': 'Office',
  'contact.info.officeStreet': 'Baštová 1467/18, 1. floor',
  'contact.info.person': 'Contact person',
  'contact.info.phone': 'Phone number',
  'contact.info.position': 'Position',
  'contact.info.regNo': 'Reg. No',
  'contact.info.vatId': 'VAT ID',
  'contact.map.warningMessage': 'The google maps is currently unavailable.',
  'cookiesInfo.content.section1': 'What are cookies?',
  'cookiesInfo.content.section1.list1.item1': 'To enable certain functions',
  'cookiesInfo.content.section1.list1.item2': 'To provide analytics',
  'cookiesInfo.content.section1.list1.item3': 'To store your preferences',
  'cookiesInfo.content.section1.list1.item4':
    'To enable ad delivery and behavioral advertising',
  'cookiesInfo.content.section1.paragraph1':
    'Cookies are a small text files that are stored in your web browser that allows {company} or a third party to recognize you. Cookies can be used to collect, store and share bits of information about your activities across websites, including on {company} website.',
  'cookiesInfo.content.section1.paragraph2':
    'Cookies might be used for the following purposes:',
  'cookiesInfo.content.section1.paragraph3':
    '{company} uses both session cookies and persistent cookies.',
  'cookiesInfo.content.section1.paragraph4':
    'A session cookie is used to identify a particular visit to our Website. These cookies expire after a short time, or when you close your web browser after using our Website. We use these cookies to identify you during a single browsing session, such as when you log into our Website.',
  'cookiesInfo.content.section1.paragraph5':
    'A persistent cookie will remain on your devices for a set period of time specified in the cookie. We use these cookies where we need to identify you over a longer period of time. For example, we would use a persistent cookie if you asked that we keep you signed in.',
  'cookiesInfo.content.section2':
    'How do third parties use cookies on the {company} Website?',
  'cookiesInfo.content.section2.paragraph1':
    "Third party companies like analytics companies and ad networks generally use cookies to collect user information on an anonymous basis. They may use that information to build a profile of your activities on the {company} Website and other websites that you've visited.",
  'cookiesInfo.content.section3': 'What are your cookies options?',
  'cookiesInfo.content.section3.paragraph1':
    "If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser.",
  'cookiesInfo.content.section3.paragraph2':
    'Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.',
  'cookiesInfo.content.section4':
    'Where can I find more information about cookies?',
  'cookiesInfo.content.section4.paragraph1':
    'You can learn more about cookies by visiting the following third party websites:',
  'cookiesInfo.content.section4.list1.item1.link1': 'HTTP cookie – Wikipedia',
  'cookiesInfo.content.section4.list1.item1.link1.href':
    'https://en.wikipedia.org/wiki/HTTP_cookie',
  'cookiesInfo.content.section4.list1.item2.link1': 'All About Cookies.org',
  'cookiesInfo.content.section4.list1.item2.link1.href':
    'https://allaboutcookies.org/',
  'cookiesInfo.content.section4.list1.item3.link1': 'About.com Browser Guide',
  'cookiesInfo.content.section4.list1.item3.link1.href':
    'https://www.lifewire.com/how-to-delete-cookies-2617981',
  'cookiesInfo.popup.link': 'More information',
  'cookiesInfo.popup.text':
    'This website uses cookies to ensure you get the best experience on our website.',
  'cookiesInfo.title': 'Cookies Policy',
  'cookiesInfo.titleText':
    "This website, sincersoft.com (the 'Website'), is operated by Sincersoft.",
  'copyright.info': '© {year} Sincersoft s.r.o. All rights reserved.',
  'home.aboutUs.logoText': 'What They Say About Us',
  'home.aboutUs.textAD':
    "With Sincersoft we found a development partner that has the same approach in 'customer orientation' and 'quality' as ourselves. Probably this was the reason why the integration of Sincersoft in various projects went so smoothly - regardless whether single developers joined our teams or the responsibility for the whole PL development in projects had been completely transferred to Sincersoft. In the end not only we but also our customers are highly satisfied with the results of our common work - and this is what finally counts.",
  'home.aboutUs.textJK':
    'I have worked with Juraj for over two years and was always satisfied with the delivered code. In my humble opinion, Juraj is an experienced front-end developer (JavaScript, React). I really appreciate his systematic approach to work, thoroughness and reliability.',
  'home.aboutUs.textMV':
    'I can only recommend cooperation with Sincersoft. High professionalism and attention to detail are a matter of course. The delivery includes functional tests and clear documentation. \n' +
    '\n' +
    'Overall, the work done is top-notch. An equally important point of cooperation is communication, and in this case, it is exemplary and to the satisfaction of all participants.\n' +
    '\n' +
    'Whenever our company needs to use external capacity to develop a front-end, Sincersoft is the first choice.',
  'home.aboutUs.textSF':
    "Cooperation with the company Sincersoft is a positive experience for us. Our back-end team in Bratislava, the front-end team in Kežmarok and the customer in Czech republic manage to cooperate together effectively and proceed with remote project in such way to be all project deadlines kept. As a great benefit of the Sincersoft company I perceive their interest in a whole project and an effort for finding best solutions in each layer of an application. We value the Sincersoft company as a reliable, creative and flexible partner. And that's also the reason, why we look forward to next common projects.",
  'home.clients.logo': 'Who Works With Us',
  'home.company.numbers.logo': 'Our company in numbers',
  'home.company.numbers.yearsOfExperience': 'Years of experience',
  'home.company.numbers.clients': 'Clients satisfied',
  'home.company.numbers.projects': 'Projects',
  'home.company.numbers.projectsHours': 'Hours worked on projects',
  'home.company.numbers.pipelines': 'Minutes spent in pipelines',
  'home.company.numbers.countries': 'Projects outside Slovakia',
  'home.world.map.logo': 'Where they already know our work',
  'home.projects.logoText': 'What We Have Done',
  'home.projects.project.haidi.headline':
    'ReactJS application for displaying result of document (text) processing.',
  'home.projects.project.haidi.name': 'HAIDi',
  'home.projects.project.haidi.text':
    'We implemented interesting components as sortable and filterable grids, different types of filtering components, generated forms and layouts from static definition files, filterable reports, interactive rendered documents from (non-HTML) markup data and many more. Behaviour of many components is configurable with static definition files allowing HAIDi to be a multipurpose application.',
  'home.projects.project.badger.headline':
    'ReactJS chat application (with robot on the server) – preparing for robot chat widget in future.',
  'home.projects.project.badger.name': 'Badger Face',
  'home.projects.project.badger.text':
    'Besides a simple text message, the user is allowed to send a file or record a voice message and give feedback rating to the robot answer. The conversation list component displays different types of messages like file or image message from robot, link snippet and inputs for sending a structured answer to robot. The WebSocket API is used as an interface for communication between back-end and front-end.',
  'home.projects.project.pigeonStandards.headline':
    'ReactJS and React Native application displaying standards of homing pigeon races.',
  'home.projects.project.pigeonStandards.name': 'Homing Pigeon Standards',
  'home.projects.project.pigeonStandards.text':
    'We implemented whole application (back-end and front-end part). Source data in LaTeX format (better for modifying by a human - a standard writer) are parsed and transformed into json data (better for manipulating in a front-end application). Both the web and native front-end applications use the same base part to prevent duplication of code and functionality.',
  'home.projects.project.salusPopuli.headline':
    'Responsive ReactJS application for investment fund access and management.',
  'home.projects.project.salusPopuli.name': 'Salus Populi',
  'home.projects.project.salusPopuli.text':
    "The application is built on a responsive design, as the customer's request was to allow use it on the desktop and also in the mobile version of the web browser. In this application, we also used our experience with a grid component, which allows users to sort or filter the displayed records. Users with different roles access the application and for each of them the appropriate agenda is displayed. A clear presentation of data in the graph is also a matter of course, where in the tooltip (it works well also in the mobile view) users see the current status of their investments with the appropriate changes. In addition to the application itself, we also prepared the Investment Calculator as a separate widget, which allows the customer to incorporate the calculator into his presentation pages.",
  'home.skills.description':
    ' The basic aspects of web front-end development - HTML, CSS, JavaScript, but we specialize to applications based on the React framework.',
  'home.skills.logo': 'Our Skills',
  'home.tools.description':
    'We are trying to use current versions of development support tools.',
  'home.tools.logo': 'What We Use',
  'home.title': 'Sincerely',
  'home.titleItemModernDesign': 'Modern design',
  'home.titleItemReliableFunctionality': 'Reliable functionality',
  'home.titleText':
    'We offer the quality of our web development services to fulfill your satisfaction.',
  'home.titleSubText': 'What We Have Done',
  'home.work.logoText': 'How We Work',
  'home.work.parAO': '’Remote work is the future of work’',
  'home.work.parMD':
    '’Technology now allows people to connect anytime, anywhere, to anyone in the world, from almost any device. This is dramatically changing the way people work, facilitating 24/7 collaboration with colleagues who are dispersed across time zones, countries, and continents. ’',
  'home.work.parSRB':
    '’We like to give people the freedom to work where they want, safe in the knowledge that they have the drive and expertise to perform excellently, whether they at their desk or in their kitchen. Yours truly has never worked out of an office, and never will.’',
  'home.work.parHS':
    '’The whole conversation is about how remote work is different, instead of being about the amazing tools we have at our disposal that remote teams and non-remote teams are able to use at any time. We have this opportunity to have a lot more freedom in our environment compared to when we had to be in an office, or even in school, 40 hours per week.’',
  'home.work.parKB':
    '’Do you want to access talent everywhere, or just in specific markets? If the answer is everywhere, you need to be at least open to the possibility of remote work — it opens doors to attracting and retaining talent around the world, literally and figuratively.’',
  'home.work.parSM':
    "’Remote work is this incredible invitation to really get good at building inclusive cultures where there's a wide variety of types of people, and to build a culture where everyone feels included and everyone is experiencing ongoing growth and development on a regular basis. That's the challenge, and it's not an easy one. But the business isn't easy.’",
  'home.work.parCL':
    "’You can never over-communicate enough as a leader at a company, but at a remote company, nothing could be truer. Because you don't physically see people in-person, information doesn't spread in the same way, so leaders need to do the heavy lifting for evangelizing the message.’",
  'home.work.secondParagraph':
    'We are set on remote work form the beginning of our company. We like it, hence we are striving to continuously improve our efficiency as remote workers. Beside that, we are trying to exploit all the benefits of remote work on both sides of the IT business when working with our clients as well as within our team.',
  'home.sliding.button.backToTop': 'Back to top',
  'home.service.detail': 'Show more',
  'navigation.aboutUs': 'About Us',
  'navigation.contact': 'Contact',
  'navigation.blog': 'Blog',
  'navigation.home': 'Home',
  'navigation.references': 'References',
  'navigation.services': 'Services',
  'page.aboutUs.description':
    'Characteristics, history and team of the Sincersoft company.',
  'page.aboutUs.title': 'About us',
  'page.blog.description': 'Blog of the Sincersoft company.',
  'page.blog.title': 'Blog',
  'page.contacts.description': 'Contacts of the Sincersoft company.',
  'page.contacts.title': 'Contacts',
  'page.cookiesInfo.description': 'Information about cookies policy.',
  'page.cookiesInfo.title': 'Cookies Policy',
  'page.services.title': 'Choose service you need',
  'page.services.description': 'Services provided by Sincersoft company.',
  'page.home.description':
    'Providing reliable and long-term software solutions for your business. Our focus lies on a trustworthy relationship with you, our client. We will handle your projects by its life cycle from precise analysis, efficient implementation to a valuable product.',
  'page.references.description':
    'References and projects of the Sincersoft company.',
  'page.references.title': 'References',
  'reference.itemButtonText': 'project details',
  'reference.preview.showMore': 'Show more',
  'reference.project.badger.images.chatLayout': 'Chat layout',
  'reference.project.badger.images.chatWithTutorial':
    'Chat layout with tutorial panel controlled by robot',
  'reference.project.badger.images.dragAndDropFileUpload':
    'Drag and drop file upload',
  'reference.project.badger.images.messageFeedback':
    'Message feedback selector',
  'reference.project.badger.images.linkSnippetMessage':
    'Link snippet message in chat history',
  'reference.project.badger.images.fileMessage': 'File message in chat history',
  'reference.project.badger.images.imageMessage':
    'Image message in chat history',
  'reference.project.badger.images.messageWithFeedback':
    'Message with feedback in chat history',
  'reference.project.badger.images.switchingAvatar':
    'Switching avatar feature (switching command message is sent in the background)',
  'reference.project.badger.images.inputsInMessage':
    'Inputs in message in chat history',
  'reference.project.badger.images.multipleTypeOfInputs':
    'Interface allows to send multiple type of input messages (text, voice, file)',
  'reference.project.haidi.images.appLayout': 'Application layout',
  'reference.project.haidi.images.caseDetail':
    'Case detail (object that wraps groups of documents)',
  'reference.project.haidi.images.documentLayout': 'Document layout',
  'reference.project.haidi.images.filterDetail': 'Selected filters',
  'reference.project.haidi.images.filteredReport':
    'Filtered reports (both parts - table and chart)',
  'reference.project.haidi.images.listFiltered': 'Filtered list',
  'reference.project.haidi.images.renderedDocument':
    'Interactive document rendered from (non-HTML) markup',
  'reference.project.haidi.images.reportWithFilter': 'Reports can be filtered',
  'reference.project.haidi.images.sortableAndFilterableGrid':
    'Grids are pageable, sortable and filterable',
  'reference.project.haidi.images.timelines':
    'Timeline data presented in chart and table form',
  'reference.project.haidi.images.generatedForm':
    'Form generated from static configuration (scheme)',
  'reference.project.pigeonStandards.images.pigeonStandardDetail':
    'Detail of pigeon standard in web application',
  'reference.project.pigeonStandards.images.pigeonStandardDetailMobile':
    'Detail of pigeon standard in native application',
  'reference.project.salusPopuli.images.overview':
    'The home page shows the client the most important things and allows him to make a withdraw or deposit in the fund',
  'reference.project.salusPopuli.images.clients':
    'Brokers always have an overview of their clients',
  'reference.project.salusPopuli.images.graph':
    'In the graph of transactions, the client clearly sees the current value of his investments, even with changes over time',
  'reference.project.salusPopuli.images.invitations':
    'A clear display of invited clients is also part of the application',
  'reference.project.salusPopuli.images.login': 'Standard login form',
  'reference.project.salusPopuli.images.modeler':
    'Upon registration, each client can model the estimated development of their investments based on their expected deposits',
  'reference.project.salusPopuli.images.reg_questionnaire':
    'The registration process is complemented by an interactive tour, in which the application guides user through the individual registration steps',
  'reference.project.salusPopuli.images.transaction':
    'Of course, a clear grid component is not missing in the application',
  'reference.title': 'What we have done',
  'reference.titleText': 'Our projects are talking instead of us',
};
