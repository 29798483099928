import {find} from 'lodash-es';

import CookiesUtils from 'src/js/common/utils/cookies_utils';
import TranslateUtils from 'src/js/common/utils/translate_utils';

import {CHANGE_LOCALE} from './i18n_action_types';
import {COOKIE_LOCALE, I18N_REDUCER_STATE_ID} from './i18n_constants';

export function changeLocale(locale, setCookies = true) {
  if (setCookies) {
    CookiesUtils.setCookie(COOKIE_LOCALE, locale);
  }
  return {type: CHANGE_LOCALE, payload: {locale}};
}

export function initLocale() {
  return (dispatch, getState) => {
    const cookieLocale = CookiesUtils.getCookie(COOKIE_LOCALE);
    const defaultLocale = cookieLocale || TranslateUtils.getLocaleByDomain();
    const usedLanguages = getState()[I18N_REDUCER_STATE_ID].languages;
    if (
      usedLanguages &&
      find(usedLanguages, (language) => language.locale === defaultLocale)
    ) {
      // allowed locale, change to it
      if (defaultLocale !== getState()[I18N_REDUCER_STATE_ID].selectedLocale) {
        dispatch(changeLocale(defaultLocale, cookieLocale));
      }
    }
  };
}
