import * as at from './contacts_action_types';

export const initialState = {
  isLoading: false,
  wasSuccessFull: undefined,
};

export function contactsReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case at.CONTACT_FORM_SUBMIT:
      newState = {
        ...state,
        isLoading: true,
      };
      break;
    case at.CONTACT_FORM_SUBMIT_FAILURE:
      newState = {
        ...state,
        isLoading: false,
        wasSuccessFull: false,
      };
      break;
    case at.CONTACT_FORM_SUBMIT_SUCCESS:
      newState = {
        ...state,
        isLoading: false,
        wasSuccessFull: true,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
}
