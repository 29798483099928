import React from 'react';

import {
  PAGE_LAYOUT_ROUTE_ABOUT_US,
  PAGE_LAYOUT_ROUTE_BLOG,
  PAGE_LAYOUT_ROUTE_CONTACTS,
  PAGE_LAYOUT_ROUTE_HOME,
  PAGE_LAYOUT_ROUTE_REFERENCE,
  PAGE_LAYOUT_ROUTE_SERVICES,
} from 'src/js/containers/layout/layout_constants.js';
import {NavigationItem} from 'src/js/containers/navigation';

import Navigation from './Navigation';

interface Props {}

interface State {
  leftNavigationData: NavigationItem[];
  rightNavigationData: NavigationItem[];
}

class NavigationContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      leftNavigationData: [
        {
          label: 'navigation.home',
          route: PAGE_LAYOUT_ROUTE_HOME,
          exact: true,
        },
        {
          label: 'navigation.references',
          route: PAGE_LAYOUT_ROUTE_REFERENCE,
          exact: false,
        },
        {
          label: 'navigation.services',
          route: PAGE_LAYOUT_ROUTE_SERVICES,
          exact: false,
        },
      ],
      rightNavigationData: [
        {
          label: 'navigation.aboutUs',
          route: PAGE_LAYOUT_ROUTE_ABOUT_US,
          exact: false,
        },
        // TODO: uncomment when blog is ready
        // {
        //   label: 'navigation.blog',
        //   route: PAGE_LAYOUT_ROUTE_BLOG,
        //   exact: false,
        // },
        {
          label: 'navigation.contact',
          route: PAGE_LAYOUT_ROUTE_CONTACTS,
          exact: false,
        },
      ],
    };
  }

  render() {
    const {leftNavigationData, rightNavigationData} = this.state;
    return (
      <Navigation
        leftNavigationData={leftNavigationData}
        rightNavigationData={rightNavigationData}
      />
    );
  }
}

NavigationContainer.displayName = 'NavigationContainer';

export default NavigationContainer;
