import PropTypes from 'prop-types';
import React from 'react';
// import {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {Route, Routes} from 'react-router-dom';

import asyncRoute from 'src/js/common/components/asyncRoute';
// import LoadingContainer from 'src/js/common/loading/LoadingContainer';
import {
  PAGE_LAYOUT_ROUTE_ABOUT_US,
  PAGE_LAYOUT_ROUTE_BLOG,
  PAGE_LAYOUT_ROUTE_CONTACTS,
  PAGE_LAYOUT_ROUTE_COOKIES_INFO,
  PAGE_LAYOUT_ROUTE_HOME,
  PAGE_LAYOUT_ROUTE_REFERENCE,
  PAGE_LAYOUT_ROUTE_ROUTE_NAME,
  PAGE_LAYOUT_ROUTE_SERVICES,
} from 'src/js/containers/layout';
import styles from 'src/js/containers/layout/components/PageLayout.module.less';
import NavigationContainer from 'src/js/containers/navigation/components/NavigationContainer';

import DynamicPageMetaInfo from './DynamicPageMetaInfo';

// route components are imported and loaded asynchronously
// ESLint parsing error 'Unexpected token import' cannot be disabled, but code is working correctly
const HomeContainer = asyncRoute(
  () => import('src/js/containers/home/components/HomeContainer'),
);
const AboutUsContainer = asyncRoute(
  () => import('src/js/containers/about_us/components/AboutUsContainer'),
);
const ServicesContainer = asyncRoute(
  () => import('src/js/containers/services/components/ServicesContainer'),
);
const ContactsContainer = asyncRoute(
  () => import('src/js/containers/contacts/components/ContactsContainer'),
);
const ReferenceContainer = asyncRoute(
  () => import('src/js/containers/reference/components/ReferenceContainer'),
);
const BlogContainer = asyncRoute(
  () => import('src/js/containers/blog/components/BlogContainer'),
);

const ArticleDetailContainer = asyncRoute(
  () => import('src/js/containers/blog/components/ArticleDetailContainer'),
);

const ReferenceDetailContainer = asyncRoute(
  () =>
    import(
      'src/js/containers/reference/components/ReferenceDetailContainer.tsx'
    ),
);
const CookiesInfoContainer = asyncRoute(
  () =>
    import('src/js/containers/cookies_info/components/CookiesInfoContainer'),
);

const I18N = defineMessages({
  PAGE_DESCRIPTION: {
    id: 'page.home.description',
    description: 'description of Home Page',
    defaultMessage:
      'Providing reliable and long-term software solutions for your business. Our focus lies on a trustworthy relationship with you, our client. We will handle your projects by its life cycle from precise analysis, efficient implementation to a valuable product.',
  },
});

// const loaderOverlay = document.querySelector('.siAppLoaderOverlay');
// const loaderContent = document.querySelector('.siAppLoaderContent');

interface OwnProps {}

type Props = OwnProps;

const PageLayout: React.FC<Props> = ({selectedLocale}) => {
  // initial loading is hidden after first render of this component
  // useEffect(
  //     () => LoadingContainer.hideLoader(loaderOverlay, loaderContent),
  //     [],
  // );

  return (
    <div id={'pageLayout'} className={styles.siPageLayout}>
      <DynamicPageMetaInfo description={I18N.PAGE_DESCRIPTION.id}>
        <html lang={selectedLocale} />
        <meta property={'og:locale'} content={selectedLocale} />
      </DynamicPageMetaInfo>
      <NavigationContainer />
      <div>
        <Routes>
          <Route path={PAGE_LAYOUT_ROUTE_HOME} Component={HomeContainer} />
          <Route
            path={PAGE_LAYOUT_ROUTE_REFERENCE}
            Component={ReferenceContainer}
          />
          <Route
            path={`${PAGE_LAYOUT_ROUTE_REFERENCE}/:${PAGE_LAYOUT_ROUTE_ROUTE_NAME}`}
            Component={ReferenceDetailContainer}
          />
          <Route
            path={PAGE_LAYOUT_ROUTE_SERVICES}
            Component={ServicesContainer}
          />
          <Route
            path={`${PAGE_LAYOUT_ROUTE_SERVICES}/:${PAGE_LAYOUT_ROUTE_ROUTE_NAME}`}
            Component={ServicesContainer}
          />
          <Route
            path={PAGE_LAYOUT_ROUTE_ABOUT_US}
            Component={AboutUsContainer}
          />
          <Route
            path={PAGE_LAYOUT_ROUTE_CONTACTS}
            Component={ContactsContainer}
          />
          {/* TODO: uncomment when blog is ready*/}
          {/*<Route path={PAGE_LAYOUT_ROUTE_BLOG} Component={BlogContainer} />*/}
          {/*<Route*/}
          {/*  path={`${PAGE_LAYOUT_ROUTE_BLOG}/:${PAGE_LAYOUT_ROUTE_ROUTE_NAME}`}*/}
          {/*  Component={ArticleDetailContainer}*/}
          {/*/>*/}
          <Route
            path={PAGE_LAYOUT_ROUTE_COOKIES_INFO}
            Component={CookiesInfoContainer}
          />
        </Routes>
      </div>
    </div>
  );
};

PageLayout.displayName = 'PageLayout';

PageLayout.propTypes = {
  selectedLocale: PropTypes.string.isRequired,
};
export default PageLayout;
