import {find} from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import 'cookieconsent';
let cookieconsent;
// if (!import.meta.env.SSR) cookieconsent = require('cookieconsent');

import AnalyticsUtils from 'src/js/common/utils/analytics_utils';

/* eslint-disable */
import PageLayout from './PageLayout';
import {I18N_REDUCER_STATE_ID} from 'src/js/containers/i18n/i18n_constants';

/* eslint-enable */

const I18N = defineMessages({
  LINK: {
    id: 'cookiesInfo.popup.link',
    description: 'link text for cookiesInfo popup window',
    defaultMessage: 'More information',
  },
  TEXT: {
    id: 'cookiesInfo.popup.text',
    description: 'text for cookiesInfo popup window',
    defaultMessage:
      'This website uses cookies to ensure you get the best experience on our website.',
  },
});

const COOKIE_CONSENT_ELEMENT_ID = 'cookieconsent:desc';
const COOKIE_CONSENT_LINK_ELEMENT_CLASS = 'cc-link';

class PageLayoutContainer extends React.Component {
  static initCookiesPopUp(props) {
    const messageText = props.intl.formatMessage(I18N.TEXT);
    const linkText = props.intl.formatMessage(I18N.LINK);

    const isReactSnap = navigator.userAgent === 'ReactSnap';
    if (!isReactSnap) {
      const cookiesElement = document.getElementById(COOKIE_CONSENT_ELEMENT_ID);
      // do not initialize popup multiple times
      if (!cookiesElement) {
        // display box for cookies information
        // use demo configurator (https://cookieconsent.osano.com/download/) for improving configuration
        window.cookieconsent.initialise({
          palette: {
            popup: {
              background: '#252e39',
            },
            button: {
              background: '#29abe2',
              text: '#ffffff',
            },
          },
          theme: 'classic',
          position: 'bottom-left',
          content: {
            message: messageText,
            dismiss: 'OK',
            link: linkText,
            href: '/cookies-info',
            target: '',
          },
        });
      } else {
        // popup is already there, update labels
        const popupTextElement = document.getElementById(
          COOKIE_CONSENT_ELEMENT_ID,
        );
        const popupLinkElement = popupTextElement.querySelector(
          `.${COOKIE_CONSENT_LINK_ELEMENT_CLASS}`,
        );
        const textNode = find(
          popupTextElement.childNodes,
          (node) => node.nodeType === 3,
        );
        if (textNode) {
          textNode.textContent = messageText;
        }
        if (popupLinkElement) {
          popupLinkElement.textContent = linkText;
        }
      }
    }
  }

  constructor(props) {
    super(props);

    PageLayoutContainer.initCookiesPopUp(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      AnalyticsUtils.sendPageView(this.props.location.pathname);
    }
  }

  render() {
    const {selectedLocale} = this.props;
    return <PageLayout selectedLocale={selectedLocale} />;
  }
}

PageLayoutContainer.displayName = 'PageLayoutContainer';

PageLayoutContainer.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(
  connect((state) => ({
    selectedLocale: state[I18N_REDUCER_STATE_ID].selectedLocale,
  }))(PageLayoutContainer),
);
