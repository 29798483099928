import {debounce} from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import AnalyticsUtils from 'src/js/common/utils/analytics_utils';
import {changeLocale} from 'src/js/containers/i18n/i18n_actions';
import {I18N_REDUCER_STATE_ID} from 'src/js/containers/i18n/i18n_constants';

import LanguageSwitch from './LanguageSwitch';

class LanguageSwitchContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChangeLocale = debounce(this.handleChangeLocale, 200).bind(this);
  }

  handleChangeLocale(locale: string) {
    if (locale !== this.props.selectedLocale) {
      AnalyticsUtils.sendEvent('Changed language', {
        event_category: 'inputs',
        event_label: locale,
      });
      this.props.changeLocale(locale);
    }
  }

  render() {
    const {itemClass, itemWrapper, languages, selectedLocale} = this.props;
    return (
      <LanguageSwitch
        itemClass={itemClass}
        itemWrapper={itemWrapper}
        languages={languages}
        selectedLocale={selectedLocale}
        changeLocale={this.handleChangeLocale}
      />
    );
  }
}

LanguageSwitchContainer.displayName = 'LanguageSwitchContainer';

LanguageSwitchContainer.propTypes = {
  itemClass: PropTypes.string,
  itemWrapper: PropTypes.func,
};

LanguageSwitchContainer.defaultProps = {
  itemClass: undefined,
  itemWrapper: undefined,
};

export default connect(
  (state) => ({
    languages: state[I18N_REDUCER_STATE_ID].languages,
    selectedLocale: state[I18N_REDUCER_STATE_ID].selectedLocale,
  }),
  (dispatch) => ({
    changeLocale: (locale, setCookies) =>
      dispatch(changeLocale(locale, setCookies)),
  }),
)(LanguageSwitchContainer);
