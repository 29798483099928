export const LAYOUT_STATE_ID = 'layout';

export const PAGE_LAYOUT_ROUTE_HOME = '/';
export const PAGE_LAYOUT_ROUTE_REFERENCE = '/references';
export const PAGE_LAYOUT_ROUTE_ABOUT_US = '/about-us';
export const PAGE_LAYOUT_ROUTE_SERVICES = '/services';
export const PAGE_LAYOUT_ROUTE_CONTACTS = '/contacts';
export const PAGE_LAYOUT_ROUTE_BLOG = '/blog';
export const PAGE_LAYOUT_ROUTE_COOKIES_INFO = '/cookies-info';
export const PAGE_LAYOUT_ROUTE_ROUTE_NAME = 'routeName';
