import {IntlShape} from 'react-intl';

import {LOCALE_EN, LOCALE_SK} from 'src/js/containers/i18n/i18n_constants';

const TranslateUtils = {
  hasTranslation(label: string, translateService: IntlShape) {
    return (
      translateService &&
      translateService.messages &&
      translateService.messages[label]
    );
  },

  translate(label: string, translateService: IntlShape, values = undefined) {
    let translatedText = label;
    if (TranslateUtils.hasTranslation(label, translateService)) {
      translatedText = translateService.formatMessage({id: label}, values);
    }
    return translatedText;
  },

  getLocaleByDomain() {
    const hostname = window.location.hostname;
    const tld = hostname.substr(hostname.lastIndexOf('.') + 1);
    let result;
    switch (tld) {
      case 'sk':
        result = LOCALE_SK;
        break;
      default:
        result = LOCALE_EN;
    }
    return result;
  },
};

export default TranslateUtils;
