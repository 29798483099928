import {defineMessages} from 'react-intl';

import {BudgetOptions} from 'src/js/containers/contacts/contacts_types.ts';

export const CONTACTS_REDUCER_STATE_ID = 'CONTACTS_REDUCER_STATE_ID';
export const CONTACT_FORM_MOBILE_SIZE = 600;
export const CONTACT_FORM_PHONE_NUMBER_REGEXP = '^[+]?[0-9]{0,12}$';

export const BUDGET_SELECT_OPTIONS: BudgetOptions[] = [
  {
    value: '1',
    label: 'contact.form.budgetLessThan',
  },
  {
    value: '2',
    label: 'contact.form.budgetFiveToTen',
  },
  {
    value: '3',
    label: 'contact.form.budgetTenToThirty',
  },
  {
    value: '4',
    label: 'contact.form.budgetThirtyToHundred',
  },
  {
    value: '5',
    label: 'contact.form.budgetMoreThan',
  },
];

export const CONTACTS_I18N = defineMessages({
  CONTACT_FORM_NAME: {
    id: 'contact.form.name',
    description: 'label name of form',
    defaultMessage: 'Meno',
  },
  CONTACT_FORM_EMAIL: {
    id: 'contact.form.email',
    description: 'label email of form',
    defaultMessage: 'E-mail',
  },
  CONTACT_FORM_MESSAGE: {
    id: 'contact.form.message',
    description: 'label message of form',
    defaultMessage: 'Správa',
  },
  CONTACT_FORM_SELECT: {
    id: 'contact.form.select',
    description: 'label budget of form',
    defaultMessage: 'Plánovaný rozpočet',
  },
  CONTACT_FORM_MANDATORY_EMAIL_MESSAGE: {
    id: 'contact.info.mandatoryEmailMessage',
    description: 'information message for mandatory email',
    defaultMessage: 'Email je povinný',
  },
  CONTACT_FORM_MANDATORY_MESSAGE_MESSAGE: {
    id: 'contact.info.mandatoryMessageMessage',
    description: 'information message for mandatory message',
    defaultMessage: 'Text správy je povinný',
  },
  CONTACT_FORM_MANDATORY_BUDGET_MESSAGE: {
    id: 'contact.info.mandatoryBudgetMessage',
    description: 'information message for mandatory message',
    defaultMessage: 'Výber rozpočtu je povinný',
  },
  CONTACT_FORM_MANDATORY_EMAIL_AND_MESSAGE_MESSAGE: {
    id: 'contact.info.mandatoryEmailAndMessageMessage',
    description: 'information message for mandatory email and message',
    defaultMessage: 'Email a text správy je povinný',
  },
  CONTACT_FORM_INFO: {
    id: 'contact.form.info',
    description: 'Contact form info text ',
    defaultMessage:
      'Write us about your project and we will contact you in 24 hours and suggest the next steps.',
  },
  CONTACT_FORM_PHONE_NUMBER: {
    id: 'contact.form.phoneNumber',
    description: 'label phone number of form',
    defaultMessage: 'Phone number',
  },
});

export const CONTACT_INFO_I18N = defineMessages({
  CONTACT_INFO_PERSON: {
    id: 'contact.info.person',
    description: 'label of contact person',
    defaultMessage: 'Kontaktná osoba',
  },
  CONTACT_INFO_POSITION: {
    id: 'contact.info.position',
    description: 'label of position',
    defaultMessage: 'Pozícia',
  },
  CONTACT_INFO_PHONE: {
    id: 'contact.info.phone',
    description: 'label of phone number',
    defaultMessage: 'Telefón',
  },
  CONTACT_INFO_BILLING_ADDRESS: {
    id: 'contact.info.billingAddress',
    description: 'label of billing address',
    defaultMessage: 'fakturačná adresa',
  },
  CONTACT_INFO_OFFICE: {
    id: 'contact.info.office',
    description: 'label of office',
    defaultMessage: 'Kancelária',
  },
  CONTACT_INFO_REG_NO: {
    id: 'contact.info.regNo',
    description: 'company registration number',
    defaultMessage: 'IČO',
  },
  CONTACT_INFO_VAT_ID: {
    id: 'contact.info.vatId',
    description: 'value added tax id',
    defaultMessage: 'IČ DPH',
  },
  CONTACT_INFO_HEADQUARTERS: {
    id: 'contact.info.headquarters',
    description: 'label of office',
    defaultMessage: 'Sídlo',
  },
  CONTACT_INFO_BILLING_INFO: {
    id: 'contact.info.billingInfo',
    description: 'label of billing info',
    defaultMessage: 'Fakturačné údaje',
  },
  CONTACT_INFO_EMAIL_SECURITY: {
    id: 'contact.info.emailSecurityInfo',
    description: 'description why email is read only',
    defaultMessage: 'for security reasons, email is read only',
  },
  CONTACT_INFO_EMAIL: {
    id: 'contact.form.email',
    description: 'label email of form',
    defaultMessage: 'E-mail',
  },
  CONTACT_INFO_LAW: {
    id: 'contact.form.law',
    description: 'law text of form',
    defaultMessage:
      'Spoločnosť Sincersoft s.r.o. je zapísaná v Obchodnom registri Okresného súdu Prešov, vložka 37460/P',
  },
});
