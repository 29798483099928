import React from 'react';
import {connect} from 'react-redux';

import {removeLoader, addLoader} from 'src/js/containers/layout/layout_actions';
import {LAYOUT_STATE_ID} from 'src/js/containers/layout/layout_constants.js';

const APP_LOADER_HIDDEN_CLASS_NAME = 'siAppLoaderHidden';

class LoadingContainer extends React.Component {
  static showLoader(loaderOverlay, loaderContent) {
    if (
      loaderOverlay &&
      loaderOverlay.classList.contains(APP_LOADER_HIDDEN_CLASS_NAME)
    ) {
      loaderOverlay.classList.remove(APP_LOADER_HIDDEN_CLASS_NAME);
    }
    if (
      loaderContent &&
      loaderContent.classList.contains(APP_LOADER_HIDDEN_CLASS_NAME)
    ) {
      loaderContent.classList.remove(APP_LOADER_HIDDEN_CLASS_NAME);
      loaderContent.style.animationPlayState = 'running';
    }
  }

  static hideLoader(loaderOverlay, loaderContent) {
    if (
      loaderOverlay &&
      !loaderContent.classList.contains(APP_LOADER_HIDDEN_CLASS_NAME)
    ) {
      loaderOverlay.classList.add(APP_LOADER_HIDDEN_CLASS_NAME);
    }
    if (
      loaderContent &&
      !loaderContent.classList.contains(APP_LOADER_HIDDEN_CLASS_NAME)
    ) {
      loaderContent.classList.add(APP_LOADER_HIDDEN_CLASS_NAME);
      loaderContent.style.animationPlayState = 'paused';
    }
  }

  constructor() {
    super();

    this.loaderOverlay = document.querySelector('.siAppLoaderOverlay');
    this.loaderContent = document.querySelector('.siAppLoaderContent');
  }

  UNSAFE_componentWillMount() {
    this.props.addLoader();
  }

  componentWillUnmount() {
    this.props.removeLoader();
    if (this.props.loadersCount <= 1) {
      LoadingContainer.hideLoader(this.loaderOverlay, this.loaderContent);
    }
  }

  render() {
    const {loadersCount} = this.props;
    if (loadersCount > 0) {
      LoadingContainer.showLoader(this.loaderOverlay, this.loaderContent);
    }
    return null;
  }
}

LoadingContainer.displayName = 'LoadingContainer';

export default connect(
  (state) => ({
    loadersCount: state[LAYOUT_STATE_ID].loadersCount,
  }),
  (dispatch) => ({
    addLoader: () => dispatch(addLoader()),
    removeLoader: () => dispatch(removeLoader()),
  }),
)(LoadingContainer);
