import {
  ADD_LOADER,
  TOGGGLE_DISPLAY_PRICING_INFO,
  REMOVE_LOADER,
} from './layout_action_types';

export function addLoader() {
  return (dispatch) => dispatch({type: ADD_LOADER});
}

export function removeLoader() {
  return (dispatch) => dispatch({type: REMOVE_LOADER});
}

export function togglePricingInfo() {
  return (dispatch) => dispatch({type: TOGGGLE_DISPLAY_PRICING_INFO});
}
