import {Button} from '@chakra-ui/react';
import React from 'react';

interface OwnProps {
  changeLocale: (locale: string) => void;
  itemClass?: string;
  selectedLocale: string;
  lang: Record<string, any>;
}

type Props = OwnProps;

const LanguageButton: React.FC<Props> = (props) => {
  const {changeLocale, itemClass, lang, selectedLocale} = props;

  const onChange = (event) => {
    changeLocale(event.target.value);
  };

  return (
    <Button
      value={lang.locale}
      color={selectedLocale === lang.locale ? 'brand.900' : 'brand.500'}
      type={'button'}
      variant={'ghost'}
      title={lang.label}
      onClick={onChange}
    >
      {lang.short}
    </Button>
  );
};

LanguageButton.displayName = 'LanguageButton';

export default LanguageButton;
