export const HOME_REDUCER_STATE_ID = 'HOME_REDUCER_STATE_ID';

export const HOME_CLIENTS_ID = '_siHomeClients';
export const HOME_PROJECTS_ID = '_siHomeProjects';
export const HOME_SAY_ABOUT_ID = '_siHomeSayAbout';
export const HOME_SKILLS_ID = '_siHomeSkills';
export const HOME_TOOLS_ID = '_siHomeTools';
export const HOME_TOP_ID = '_siHomeTop';
export const HOME_WORK_ID = '_siHomeWork';
export const HOME_WORLD_MAP_ID = '_siHomeWorldMap';
export const HOME_COMPANY_NUMBERS_ID = '_siHomeCompanyNumbers';

export const HOME_SLIDING_BUTTON_LINKS = [
  {id: HOME_COMPANY_NUMBERS_ID, labelId: 'home.company.numbers.logo'},
  {id: HOME_WORLD_MAP_ID, labelId: 'home.world.map.logo'},
  {id: HOME_PROJECTS_ID, labelId: 'home.projects.logoText'},
  {id: HOME_SAY_ABOUT_ID, labelId: 'home.aboutUs.logoText'},
  {id: HOME_CLIENTS_ID, labelId: 'home.clients.logo'},
  {id: HOME_TOP_ID, labelId: 'home.sliding.button.backToTop'},
];
